var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c(
          "div",
          {
            staticClass: "d-flex align-center",
            staticStyle: { width: "100%" }
          },
          [
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c(
                    "span",
                    { staticClass: "primary--text font-weight-bold" },
                    [_vm._v("Applications")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { loading: _vm.applicationsFetching, icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.$store.dispatch(
                            "snapshotStore/fetchApplications",
                            _vm.$route.params.snid
                          )
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("refresh")])],
                    1
                  )
                ],
                1
              ),
              _vm.applicationsFetching
                ? _c("span", { staticClass: "caption text-uppercase" }, [
                    _vm._v("fetching applications data...")
                  ])
                : _c("span", { staticClass: "caption text-uppercase" }, [
                    _vm._v(
                      _vm._s(_vm.applicationsWithStatus.length) +
                        " applications found"
                    )
                  ])
            ]),
            _c("v-spacer"),
            (_vm.isDevelopment === true && _vm.isSpaceAdmin === true) ||
            (_vm.isDevelopment === true &&
              _vm.currentSpaceType !== _vm.spaceTypes.EDUCATION_SPACE &&
              _vm.isInstanceEditor === true)
              ? _c(
                  "v-btn",
                  {
                    attrs: {
                      to: {
                        name: "snapshot-applications-add",
                        params: {
                          oid: _vm.$route.params.oid,
                          sid: _vm.$route.params.sid,
                          iid: _vm.$route.params.iid,
                          snid: _vm.$route.params.snid
                        }
                      },
                      text: "",
                      color: "primary",
                      disabled: _vm.applicationsFetching
                    }
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [_vm._v("add")]),
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("add new application")
                    ])
                  ],
                  1
                )
              : _vm.isDevelopment === true &&
                _vm.isSpaceAdmin === false &&
                _vm.currentSpaceType === _vm.spaceTypes.EDUCATION_SPACE &&
                _vm.isInstanceEditor === true
              ? _c(
                  "v-dialog",
                  {
                    attrs: { "max-width": "500" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                { attrs: { text: "", color: "primary" } },
                                on
                              ),
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("add")
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("add new application")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.AddAppdialog,
                      callback: function($$v) {
                        _vm.AddAppdialog = $$v
                      },
                      expression: "AddAppdialog"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", [
                          _c(
                            "span",
                            { staticClass: "headline primary--text" },
                            [_vm._v(" Insufficient privileges ")]
                          )
                        ]),
                        _c("v-divider"),
                        _c("v-card-text", { staticClass: "mt-1" }, [
                          _c("p", [
                            _vm._v(
                              " New applications can be added only by course admins. If you require an additional application for your work, please ask a course admin to add the application. As a reference, they can do it at the current URL: "
                            )
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-center justify-space-between mt-2"
                            },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "span",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "secondary--text text-truncate"
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.currentURL.substring(
                                                      0,
                                                      100
                                                    )
                                                  ) +
                                                  "... "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [_c("span", [_vm._v(_vm._s(_vm.currentURL))])]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "div",
                                            _vm._g({}, on),
                                            [
                                              _c("CopyToClipboard", {
                                                attrs: {
                                                  textToCopy: _vm.currentURL,
                                                  buttonColor: "primary",
                                                  isTextButton: true,
                                                  iconButton: true,
                                                  isSmall: true
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [_c("span", [_vm._v("Copy to clipboard")])]
                              )
                            ],
                            1
                          )
                        ]),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "secondary", text: "" },
                                on: {
                                  click: function($event) {
                                    _vm.AddAppdialog = false
                                  }
                                }
                              },
                              [_vm._v(" close ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-btn",
              {
                attrs: {
                  disabled:
                    _vm.applicationsFetching ||
                    !_vm.applicationsWithStatus.length,
                  color: "primary",
                  icon: ""
                },
                on: {
                  click: function($event) {
                    _vm.expand = !_vm.expand
                  }
                }
              },
              [_c("v-icon", [_vm._v("search")])],
              1
            )
          ],
          1
        )
      ]),
      _c("v-card-text", [
        _vm.applicationsFetching
          ? _c(
              "div",
              [
                _c("v-skeleton-loader", {
                  staticClass: "mx-auto",
                  attrs: { type: "table" }
                })
              ],
              1
            )
          : _c(
              "div",
              [
                _c("v-text-field", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.expand,
                      expression: "expand"
                    }
                  ],
                  attrs: {
                    label: "Search for an application...",
                    autofocus: ""
                  },
                  model: {
                    value: _vm.search,
                    callback: function($$v) {
                      _vm.search = $$v
                    },
                    expression: "search"
                  }
                }),
                _vm.applicationsWithStatus &&
                _vm.applicationsWithStatus.length > 0
                  ? _c("v-data-table", {
                      attrs: {
                        search: _vm.search,
                        "item-key": "aid",
                        headers: _vm.headersFiltered,
                        items: _vm.applicationsWithStatus
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "item.icon",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-center" },
                                  [
                                    _c("img", {
                                      staticClass: "mr-1",
                                      attrs: {
                                        width: "30",
                                        height: "30",
                                        src: _vm.getAppLink(item.app_type)
                                      }
                                    })
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: "item.long_id",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _vm.isDevelopment === true
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "subtitle-2",
                                        on: {
                                          click: function($event) {
                                            return _vm.openApp(item.aid)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "span",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "subtitle-2 secondary--text"
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.long_id
                                                              ) +
                                                              " "
                                                          ),
                                                          _c(
                                                            "sup",
                                                            {
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "-4px",
                                                                "line-height":
                                                                  "15px",
                                                                "font-size":
                                                                  "15px !important",
                                                                opacity: "0.75"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    "x-small":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-arrow-top-right-thick"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            item.status.toUpperCase() ===
                                            "RUNNING"
                                              ? _c("span", [
                                                  _vm._v("Open application")
                                                ])
                                              : _c("span", [
                                                  _vm._v("Start application")
                                                ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _c("span", [_vm._v(_vm._s(item.long_id))])
                              ]
                            }
                          },
                          {
                            key: "item.status",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "v-chip",
                                  {
                                    staticClass: "white--text font-weight-bold",
                                    staticStyle: { opacity: "0.75" },
                                    attrs: {
                                      small: "",
                                      color:
                                        _vm.statusToColor[item.status] ||
                                        "error"
                                    }
                                  },
                                  [
                                    _vm._v(" " + _vm._s(item.status) + " "),
                                    item.status === "Starting"
                                      ? _c("v-progress-circular", {
                                          staticClass: "ml-1",
                                          attrs: {
                                            size: "16",
                                            color: "white",
                                            indeterminate: ""
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "item.description",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "span",
                                  { staticClass: "subtitle-2 primary--text" },
                                  [_vm._v(_vm._s(item.description))]
                                )
                              ]
                            }
                          },
                          {
                            key: "item.actions",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      "close-on-content-click": false,
                                      bottom: "",
                                      origin: "center center",
                                      transition: "scale-transition"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      color: "primary",
                                                      small: ""
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("more_horiz")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      [
                                        _c(
                                          "v-card-text",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  block: "",
                                                  color: "primary",
                                                  text: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.addToStaging([
                                                      item
                                                    ])
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-center",
                                                    staticStyle: {
                                                      width: "100%"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-1",
                                                        attrs: { small: "" }
                                                      },
                                                      [_vm._v("share")]
                                                    ),
                                                    _vm._v("stage")
                                                  ],
                                                  1
                                                )
                                              ]
                                            ),
                                            _vm.isDevelopment === true
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-column align-start"
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          text: "",
                                                          disabled:
                                                            item.status ===
                                                            "Stopped",
                                                          color: "primary"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.stopApp(
                                                              item.aid
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex align-center",
                                                            staticStyle: {
                                                              width: "100%"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-1",
                                                                attrs: {
                                                                  "x-small": ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-square"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v("Stop")
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    ),
                                                    _c("DeleteButton", {
                                                      attrs: {
                                                        disabled:
                                                          item.status ===
                                                          "Running",
                                                        objectType:
                                                          "application",
                                                        objectName: item.long_id.toUpperCase(),
                                                        icon: false,
                                                        buttonName:
                                                          _vm.isSpaceAdmin &&
                                                          _vm.isMasterInstance
                                                            ? "Delete for me"
                                                            : "Delete",
                                                        apiURL:
                                                          "/applications/" +
                                                          item.aid +
                                                          "/async",
                                                        id: item.aid,
                                                        isAsyncDeletion: true,
                                                        higherLevelId: parseInt(
                                                          _vm.$route.params
                                                            .snid,
                                                          10
                                                        ),
                                                        showDialog: true,
                                                        warningText:
                                                          "Are you sure you want to delete - " +
                                                          item.long_id.toUpperCase() +
                                                          " - permanently?",
                                                        fetchString:
                                                          "snapshotStore/fetchApplications"
                                                      },
                                                      on: {
                                                        error: function(
                                                          $event
                                                        ) {
                                                          return _vm.errorMessage(
                                                            $event.error
                                                          )
                                                        }
                                                      }
                                                    }),
                                                    _vm.isSpaceAdmin &&
                                                    _vm.isMasterInstance
                                                      ? _c("DeleteButton", {
                                                          attrs: {
                                                            objectType:
                                                              "application",
                                                            objectName: item.long_id.toUpperCase(),
                                                            icon: false,
                                                            buttonName:
                                                              "Delete for others",
                                                            apiURL:
                                                              "/applications/" +
                                                              item.aid +
                                                              "/async?mode=2",
                                                            id: item.aid,
                                                            isAsyncDeletion: true,
                                                            higherLevelId: parseInt(
                                                              _vm.$route.params
                                                                .snid,
                                                              10
                                                            ),
                                                            showDialog: true,
                                                            warningText:
                                                              "Are you sure you want to delete all distributed versions of - " +
                                                              item.long_id.toUpperCase() +
                                                              " - from the other instances permanently? The application would still be available for you.",
                                                            fetchString:
                                                              "snapshotStore/fetchApplications"
                                                          },
                                                          on: {
                                                            error: function(
                                                              $event
                                                            ) {
                                                              return _vm.errorMessage(
                                                                $event.error
                                                              )
                                                            }
                                                          }
                                                        })
                                                      : _vm._e(),
                                                    _vm.isSpaceAdmin &&
                                                    _vm.isMasterInstance
                                                      ? _c("DeleteButton", {
                                                          attrs: {
                                                            disabled:
                                                              item.status ===
                                                              "Running",
                                                            objectType:
                                                              "application",
                                                            objectName: item.long_id.toUpperCase(),
                                                            icon: false,
                                                            buttonName:
                                                              "Delete for all",
                                                            apiURL:
                                                              "/applications/" +
                                                              item.aid +
                                                              "/async?mode=1",
                                                            id: item.aid,
                                                            isAsyncDeletion: true,
                                                            higherLevelId: parseInt(
                                                              _vm.$route.params
                                                                .snid,
                                                              10
                                                            ),
                                                            showDialog: true,
                                                            warningText:
                                                              "Are you sure you want to delete your and all distributed versions of - " +
                                                              item.long_id.toUpperCase() +
                                                              " - permanently?",
                                                            fetchString:
                                                              "snapshotStore/fetchApplications"
                                                          },
                                                          on: {
                                                            error: function(
                                                              $event
                                                            ) {
                                                              return _vm.errorMessage(
                                                                $event.error
                                                              )
                                                            }
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.isSpaceAdmin === true &&
                                            _vm.isDevelopment === true &&
                                            _vm.isMasterInstance === true
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      loading:
                                                        _vm.startingAppForAll,
                                                      text: "",
                                                      color: "primary"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.startAppForAll(
                                                          item.aoid
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-1",
                                                        attrs: { "x-small": "" }
                                                      },
                                                      [_vm._v("launch")]
                                                    ),
                                                    _vm._v(
                                                      "Start for all users "
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1174666535
                      ),
                      model: {
                        value: _vm.selected,
                        callback: function($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected"
                      }
                    })
                  : _c(
                      "v-banner",
                      { attrs: { "single-line": "" } },
                      [
                        _c(
                          "v-avatar",
                          {
                            attrs: { slot: "icon", color: "white", size: "60" },
                            slot: "icon"
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  "x-large": "",
                                  icon: "info",
                                  color: "info"
                                }
                              },
                              [_vm._v(" info ")]
                            )
                          ],
                          1
                        ),
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c(
                            "span",
                            { staticClass: "font-weight-bold primary--text" },
                            [_vm._v("No applications found")]
                          ),
                          _c("span", [
                            _vm._v(
                              "Currently this state has no applications. "
                            ),
                            _vm.isDevelopment === true
                              ? _c(
                                  "span",
                                  { staticClass: "ma-0" },
                                  [
                                    _vm._v(
                                      " Consider adding a new application via the "
                                    ),
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-1",
                                        attrs: { color: "primary", small: "" }
                                      },
                                      [_vm._v("add")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "primary--text font-weight-bold"
                                      },
                                      [_vm._v("Add New Application")]
                                    ),
                                    _vm._v(" button above. ")
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        ])
                      ],
                      1
                    )
              ],
              1
            )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }