<template>
    <v-card flat>
        <v-card-title>
            <div class="d-flex align-center" style="width:100%">
                <div class="d-flex flex-column">
                    <div class="d-flex align-center">
                        <span class="primary--text font-weight-bold">Applications</span>
                        <v-btn :loading="applicationsFetching" @click="$store.dispatch('snapshotStore/fetchApplications', $route.params.snid)" icon
                            ><v-icon>refresh</v-icon></v-btn
                        >
                    </div>
                    <span v-if="applicationsFetching" class="caption text-uppercase">fetching applications data...</span>
                    <span v-else class="caption text-uppercase">{{ applicationsWithStatus.length }} applications found</span>
                </div>
                <v-spacer></v-spacer>
                <v-btn
                    :to="{
                        name: 'snapshot-applications-add',
                        params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                    }"
                    text
                    color="primary"
                    :disabled="applicationsFetching"
                    v-if="
                        (isDevelopment === true && isSpaceAdmin === true) ||
                            (isDevelopment === true && currentSpaceType !== spaceTypes.EDUCATION_SPACE && isInstanceEditor === true)
                    "
                >
                    <v-icon small>add</v-icon>
                    <span class="font-weight-bold">add new application</span>
                </v-btn>
                <v-dialog
                    v-else-if="isDevelopment === true && isSpaceAdmin === false && currentSpaceType === spaceTypes.EDUCATION_SPACE && isInstanceEditor === true"
                    v-model="AddAppdialog"
                    max-width="500"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" text color="primary">
                            <v-icon small>add</v-icon>
                            <span class="font-weight-bold">add new application</span>
                        </v-btn>
                    </template>

                    <v-card>
                        <v-card-title>
                            <span class="headline primary--text">
                                Insufficient privileges
                            </span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text class="mt-1">
                            <p>
                                New applications can be added only by course admins. If you require an additional application for your work, please ask a course
                                admin to add the application. As a reference, they can do it at the current URL:
                            </p>
                            <div class="d-flex align-center justify-space-between mt-2">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <span class="secondary--text text-truncate" v-on="on"> {{ currentURL.substring(0, 100) }}... </span>
                                    </template>
                                    <span>{{ currentURL }}</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on">
                                            <CopyToClipboard
                                                :textToCopy="currentURL"
                                                buttonColor="primary"
                                                :isTextButton="true"
                                                :iconButton="true"
                                                :isSmall="true"
                                            ></CopyToClipboard>
                                        </div>
                                    </template>
                                    <span>Copy to clipboard</span>
                                </v-tooltip>
                            </div>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="secondary" text @click="AddAppdialog = false">
                                close
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-btn :disabled="applicationsFetching || !applicationsWithStatus.length" color="primary" @click="expand = !expand" icon>
                    <v-icon>search</v-icon>
                </v-btn>
            </div>
        </v-card-title>
        <v-card-text>
            <div v-if="applicationsFetching">
                <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
            </div>
            <div v-else>
                <v-text-field v-show="expand" label="Search for an application..." autofocus v-model="search"></v-text-field>
                <v-data-table
                    v-if="applicationsWithStatus && applicationsWithStatus.length > 0"
                    :search="search"
                    item-key="aid"
                    :headers="headersFiltered"
                    v-model="selected"
                    :items="applicationsWithStatus"
                >
                    <template v-slot:item.icon="{ item }">
                        <div class="d-flex justify-center">
                            <img width="30" height="30" class="mr-1" :src="getAppLink(item.app_type)" />
                        </div>
                    </template>
                    <template v-slot:item.long_id="{ item }">
                        <a @click="openApp(item.aid)" class="subtitle-2" v-if="isDevelopment === true">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on" class="subtitle-2 secondary--text">
                                        {{ item.long_id }}
                                        <sup style="margin-left:-4px;line-height:15px; font-size:15px !important;opacity:0.75">
                                            <v-icon x-small>mdi-arrow-top-right-thick</v-icon>
                                        </sup>
                                    </span>
                                </template>
                                <span v-if="item.status.toUpperCase() === 'RUNNING'">Open application</span>
                                <span v-else>Start application</span>
                            </v-tooltip>
                        </a>
                        <span v-else>{{ item.long_id }}</span>
                    </template>
                    <template v-slot:item.status="{ item }">
                        <v-chip small :color="statusToColor[item.status] || 'error'" class="white--text font-weight-bold" style="opacity:0.75">
                            {{ item.status }}
                            <v-progress-circular v-if="item.status === 'Starting'" size="16" color="white" indeterminate class="ml-1" />
                        </v-chip>
                    </template>
                    <template v-slot:item.description="{ item }">
                        <span class="subtitle-2 primary--text">{{ item.description }}</span>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-menu :close-on-content-click="false" bottom origin="center center" transition="scale-transition">
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" text color="primary" small>
                                    <v-icon>more_horiz</v-icon>
                                </v-btn>
                            </template>

                            <v-card>
                                <v-card-text>
                                    <v-btn block @click="addToStaging([item])" color="primary" text>
                                        <div style="width:100%" class="d-flex align-center"><v-icon class="mr-1" small>share</v-icon>stage</div>
                                    </v-btn>
                                    <div class="d-flex flex-column align-start" v-if="isDevelopment === true">
                                        <v-btn text :disabled="item.status === 'Stopped'" color="primary" @click="stopApp(item.aid)">
                                            <div style="width:100%" class="d-flex align-center"><v-icon class="mr-1" x-small>mdi-square</v-icon>Stop</div>
                                        </v-btn>
                                        <DeleteButton
                                            :disabled="item.status === 'Running'"
                                            objectType="application"
                                            :objectName="item.long_id.toUpperCase()"
                                            :icon="false"
                                            :buttonName="isSpaceAdmin && isMasterInstance ? 'Delete for me' : 'Delete'"
                                            :apiURL="`/applications/${item.aid}/async`"
                                            :id="item.aid"
                                            :isAsyncDeletion="true"
                                            :higherLevelId="parseInt($route.params.snid, 10)"
                                            :showDialog="true"
                                            :warningText="`Are you sure you want to delete - ${item.long_id.toUpperCase()} - permanently?`"
                                            @error="errorMessage($event.error)"
                                            fetchString="snapshotStore/fetchApplications"
                                        ></DeleteButton>
                                        <DeleteButton
                                            objectType="application"
                                            v-if="isSpaceAdmin && isMasterInstance"
                                            :objectName="item.long_id.toUpperCase()"
                                            :icon="false"
                                            buttonName="Delete for others"
                                            :apiURL="`/applications/${item.aid}/async?mode=2`"
                                            :id="item.aid"
                                            :isAsyncDeletion="true"
                                            :higherLevelId="parseInt($route.params.snid, 10)"
                                            :showDialog="true"
                                            :warningText="
                                                `Are you sure you want to delete all distributed versions of - ${item.long_id.toUpperCase()} - from the other instances permanently? The application would still be available for you.`
                                            "
                                            @error="errorMessage($event.error)"
                                            fetchString="snapshotStore/fetchApplications"
                                        ></DeleteButton>
                                        <DeleteButton
                                            :disabled="item.status === 'Running'"
                                            v-if="isSpaceAdmin && isMasterInstance"
                                            objectType="application"
                                            :objectName="item.long_id.toUpperCase()"
                                            :icon="false"
                                            buttonName="Delete for all"
                                            :apiURL="`/applications/${item.aid}/async?mode=1`"
                                            :id="item.aid"
                                            :isAsyncDeletion="true"
                                            :higherLevelId="parseInt($route.params.snid, 10)"
                                            :showDialog="true"
                                            :warningText="
                                                `Are you sure you want to delete your and all distributed versions of - ${item.long_id.toUpperCase()} - permanently?`
                                            "
                                            @error="errorMessage($event.error)"
                                            fetchString="snapshotStore/fetchApplications"
                                        ></DeleteButton>
                                    </div>
                                    <v-btn
                                        v-if="isSpaceAdmin === true && isDevelopment === true && isMasterInstance === true"
                                        :loading="startingAppForAll"
                                        text
                                        color="primary"
                                        @click="startAppForAll(item.aoid)"
                                    >
                                        <v-icon class="mr-1" x-small>launch</v-icon>Start for all users
                                    </v-btn>
                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </template>
                </v-data-table>
                <v-banner v-else single-line>
                    <v-avatar slot="icon" color="white" size="60">
                        <v-icon x-large icon="info" color="info">
                            info
                        </v-icon>
                    </v-avatar>
                    <div class="d-flex flex-column">
                        <span class="font-weight-bold primary--text">No applications found</span>
                        <span
                            >Currently this state has no applications.
                            <span class="ma-0" v-if="isDevelopment === true">
                                Consider adding a new application via the
                                <v-icon color="primary" class="mr-1" small>add</v-icon>
                                <span class="primary--text font-weight-bold">Add New Application</span> button above.
                            </span></span
                        >
                    </div>
                </v-banner>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import DeleteButton from '@/components/DeleteButton'
import { appTypeAndImageLink } from '@/mixins/appTypeAndImage'
import { enumsData } from '@/mixins/enums'
const CopyToClipboard = () => import('@/components/CopyToClipboard')

export default {
    mixins: [appTypeAndImageLink, enumsData],
    components: {
        DeleteButton,
        CopyToClipboard
    },
    data() {
        return {
            search: '',
            expand: false,
            headers: [
                { text: 'Type', align: 'right', value: 'icon', width: '7%' },
                { text: 'Name', align: 'left', value: 'long_id' },
                { text: 'Status', value: 'status', align: 'center' },
                { text: 'Description', align: 'left', value: 'description' },
                { text: 'Actions', align: 'center', value: 'actions' }
            ],
            statusToColor: {
                Running: 'success',
                Starting: 'warning'
            },
            error: false,
            errorContent: '',
            dialog: false,
            linkAoid: 0,
            linkLongId: '',
            selected: [],
            AddAppdialog: false,
            startingAppForAll: false
        }
    },
    computed: {
        applicationsWithStatus: function() {
            return this.applications.map(a => {
                a.status = 'Stopped'
                const appDeployment = this.$store.state.appStore.deploymentStatus.filter(d => {
                    return d.aid.toString() === a.aid.toString()
                })
                if (appDeployment.length) {
                    if (appDeployment[0].replicas === appDeployment[0].available_replicas) {
                        a.status = 'Running'
                    } else if (appDeployment[0].replicas > appDeployment[0].available_replicas) {
                        a.status = 'Starting'
                    } else {
                        a.status = 'Stopping'
                    }
                }
                return a
            })
        },
        currentURL() {
            return window.location.href
        },
        headersFiltered: function() {
            const snapshotHeaders = ['Type', 'Name', 'Description', 'Actions']
            return this.isDevelopment
                ? this.$data.headers
                : this.$data.headers.filter(c => {
                      return snapshotHeaders.indexOf(c.text) >= 0
                  })
        },
        ...mapGetters('snapshotStore', ['isDevelopment']),
        ...mapState('snapshotStore', ['stagingObjects', 'applications', 'applicationsFetching']),
        ...mapGetters('spaceStore', ['isSpaceAdmin', 'currentSpaceType']),
        ...mapGetters('instanceStore', ['isInstanceEditor', 'isMasterInstance'])
    },
    methods: {
        addToStaging: function(data) {
            if (data.length > 0) {
                const stagingObjectsBefore = this.stagingObjects.applications.length
                var filesWithPaths = []
                var fileOrder = data.length
                data.forEach(file => {
                    var fileObject = file
                    fileObject.filePath = file.long_id
                    fileObject.fileType = 'application'
                    filesWithPaths.push(fileObject)
                    fileOrder = fileOrder - 1
                    if (fileOrder === 0) {
                        this.$store.dispatch('snapshotStore/updateStagingObjects', {
                            itemType: 'application',
                            newItems: filesWithPaths,
                            updateMode: 'add'
                        })
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Applications added to stage.',
                            snackBarTimeout: 5000,
                            snackBarIcon: 'check_circle'
                        })
                        this.$data.selected = []
                    }
                    const stagingObjectsAfter = this.stagingObjects.applications.length
                    if (stagingObjectsAfter !== stagingObjectsBefore) {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Applications added to stage.',
                            snackBarTimeout: 5000,
                            snackBarIcon: 'check_circle'
                        })
                    } else {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Selected applications already staged.',
                            snackBarTimeout: 10000,
                            snackBarIcon: 'info'
                        })
                    }
                })
            }
        },
        startAppForAll(aoid) {
            this.$data.startingAppForAll = true
            const postBody = {
                aoids: [aoid],
                explicit_editors_only: false,
                wait_secs: 6,
                max_users: 150
            }
            this.$axios
                .post(`/spaces/${this.$route.params.sid}/start_apps`, postBody)
                .then(response => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'User applications have been started!',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to start the applications',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.$data.startingAppForAll = false
                })
        },
        errorMessage: function(error) {
            if (error) {
                this.$data.error = true
                this.$data.errorContent = 'An error has occurred'
            }
        },
        stopApp: function(aid) {
            this.$store.dispatch('appStore/stopApp', { aid })
        },
        openApp: function(aid) {
            this.$router.push({
                name: 'app-open',
                params: {
                    oid: this.$route.params.oid,
                    sid: this.$route.params.sid,
                    iid: this.$route.params.iid,
                    snid: this.$route.params.snid,
                    aid: aid
                }
            })
        }
    },
    watch: {
        applicationsWithStatus: function(nextValue, preValue) {
            if (nextValue.length < preValue.length) {
                if (this.$data.selected.length === 1) {
                    this.$data.selected = []
                }
            }
        }
    },
    mounted() {
        this.$store.dispatch('snapshotStore/fetchApplications', this.$route.params.snid)
    }
}
</script>
